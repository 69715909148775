import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetApprovedRequestsDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/approvedRequests";
import { updateConfig } from "../../../store/slices/ApprovedRequests/ApprovedRequestsSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  getFormatedDate,
  getFormatedDateRange,
} from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { subDays } from "date-fns";

const useApprovedRequestsListing = ({ dashboard }) => {
  const dispatch = useDispatch();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const menuState = useSelector((state) => state.approvedRequests);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetApprovedRequestsDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: dashboard ? 10 : menuState.currentPageSize,
    page_no: dashboard ? 1 : menuState.currentPage,
    start_date: getFormatedDate(menuState?.start),
    end_date: getFormatedDate(menuState?.end),
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: currentPageSize }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = currentPageSize;
        state.currentPage = 1;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      redeemed_date: (field, data) => getFormatedDateRange(data[field]),
      user_unique_id: (field, data) => (
        <p className="pro-mb-0">{data["user"][field]}</p>
      ),
      reward_name: (field, data) => {
        return (
          <div className="pro-d-flex pro-flex-wrap pro-gap-2">
            {data?.rewardHistories?.map((value, index) => (
              <span key={index} className="pro-badge badge-grey">
                {value?.reward_name}
              </span>
            ))}
          </div>
        );
      },
      image: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image)}
            />
          </div>
        );
      },
      mobile_number: (field, data) => (
        <p className="pro-mb-0">{data["user"]?.mobile_number}</p>
      ),
      first_name: (field, data) => (
        <p className="pro-mb-0">{data["user"]?.first_name}</p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
        state.start = subDays(new Date(), 30);
        state.end = new Date();
      })
    );
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  return {
    menuState,
    mainData,
    isFetching,
    paginationOptions,
    showEditModal,
    date,
    isLoading,
    actionOptions,
    showImageModal,
    imageData,
    currentPage: menuState.currentPage,
    closeImageModal,
    handleClearClick,
    handleSearch,
    updateTableFields,
    handleActionChange,
    handleSort,
    refetch,
    handlePagination,
    handlePageSize,
    getRow,
    closeEditModal,
    handleDateRangeChange,
  };
};

export default useApprovedRequestsListing;
