import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import usePayoutListing from "./usePayoutListing";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";

const PayoutsListing = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    isFetching,
    showEditModal,
    paginationOptions,
    currentPage,
    actionOptions,
    isLoading,
    date,
    handleClearClick,
    handleSearch,
    updateTableFields,
    handleActionChange,
    handleSort,
    handlePagination,
    handlePageSize,
    closeEditModal,
    getRow,
    refetch,
    handleDateRangeChange,
  } = usePayoutListing({ dashboard });

  return (
    <>
      <HeadingGroup title={"Payouts"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          {!dashboard && (
            <div className="col">
              <SearchFilters
                data={mainData?.data?.filters}
                // onDateChange={handleDateChange}
                onSearchInput={handleSearch}
                showActions={false}
                // handleActionClick={handleEditClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                actionOptions={actionOptions?.filter(
                  (item) => item.value === 0
                )}
                onActionOptionChange={handleActionChange}
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: menuState?.search }}
                dropDownFilter={
                  <>
                    <div className="col-auto">
                      <CustomDateRangePicker
                        defaultDateRange={date}
                        handleDateRangeChange={handleDateRangeChange}
                      />
                    </div>
                  </>
                }
              />
            </div>
          )}
        </div>

        {mainData?.data?.payoutResponse?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.payoutResponse || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            // showCheckBox={hasUpdatePermission === 1 ? true : true}
            // deletable={hasDeletePermission === 1 ? true : true}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.page_size}
          />
        )}

        {!dashboard && mainData?.data?.payoutResponse?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={Math.ceil(
              mainData?.data?.total_count / menuState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {!dashboard && (
          <ModalLayout
            show={showEditModal}
            handleClose={closeEditModal}
            backdrop="static"
          >
            <div className="pro-m-5">
              <OrderColumn
                title={"Choose which columns you see"}
                refetch={refetch}
                tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                  (filteredObj, property) => {
                    filteredObj[property] = mainData?.data?.fields[property];
                    return filteredObj;
                  },
                  {}
                )}
                moduleId={mainData?.data?.table_id}
                updateData={updateTableFields}
              />
            </div>
          </ModalLayout>
        )}
      </div>
    </>
  );
};

export default PayoutsListing;
