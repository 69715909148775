import { createSlice } from "@reduxjs/toolkit";
import { subDays, subYears } from "date-fns";

const initialState = {
  //   menuDrawer: {},
  //   isProfileViewVisible:false,
  //   showEditModal: false,
  //   isLogged: false,
  //   currentPage: 1,
  //   otpGenerated: "",
  //   tableFields: {},
  //   searchData: {},
  //   searchStatus: "idle",
  //   searchKey: "",
  //   drawerTheme: "expanded",
  //   currentTheme: "light",
  //   dashboard_permissions: {},
  //   dashboard_permissions_status: "idle",
  currentPage: 1,
  currentPageSize: 10,
  start: subYears(new Date(), 1),
  end: new Date(),
  sortBy: "",
  sortOrder: "desc",
};

const PendingRequestsSlice = createSlice({
  name: "pendingRequests",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = PendingRequestsSlice.actions;

export default PendingRequestsSlice.reducer;
