import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import { ImageCard } from "../../Global/ImageCard";
import OrderColumn from "../../Global/OrderColumn";
import PriceUpdationModal from "./PriceUpdationModal";
import usePriceUpdationListing from "./usePriceUpdationListing";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";

const PriceUpdationListing = () => {
  const {
    menuState,
    mainData,
    // hasDeletePermission,
    // hasUpdatePermission,
    isFetching,
    currentPage,
    paginationOptions,
    showEditModal,
    isLoading,
    actionOptions,
    showImageModal,
    imageData,
    date,
    closeImageModal,
    handleClearClick,
    handleSearch,
    updateTableFields,
    handleSort,
    handleActionChange,
    closeDataEditModal,
    closeEditModal,
    handlePagination,
    handlePageSize,
    getRow,
    refetch,
    handleDateRangeChange,
  } = usePriceUpdationListing();

  return (
    <>
      <HeadingGroup title={"Price Updation"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={mainData?.data?.filters}
              // onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={false}
              // handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: menuState?.search }}
              dropDownFilter={
                <>
                  <div className="col-auto">
                    <CustomDateRangePicker
                      defaultDateRange={date}
                      handleDateRangeChange={handleDateRangeChange}
                    />
                  </div>
                </>
              }
            />
          </div>
        </div>

        {mainData?.data?.rewardsResponse?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.rewardsResponse || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            // showCheckBox={hasUpdatePermission === 1 ? true : true}
            // deletable={hasDeletePermission === 1 ? true : true}
            // clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.page_size}
          />
        )}

        {mainData?.data?.rewardsResponse?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={Math.ceil(
              mainData?.data?.total_count / menuState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.table_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>

        <ModalLayout
          centered={true}
          show={menuState.showCreateModal}
          handleClose={() => closeDataEditModal()}
          title={"Change Product Price"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          // propStyle={{ root: Style.modal_root }}
        >
          <PriceUpdationModal
            refetch={refetch}
            closeModal={closeDataEditModal}
          />

          {/* <BgDetails closeModal={() => closeModal()} refetch={refetch}/> */}
        </ModalLayout>
      </div>
    </>
  );
};

export default PriceUpdationListing;
