import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { submitUserVerification } from "../../../../store/slices/UserVerification/UserVerificationSlice";
import { pendingRequests } from "../../../../store/queries/pendingRequests";

const useOfferOtpVerificationModal = ({ setSeconds }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageUploadRef = useRef(null);
  const { offerDetailsData } = useSelector((state) => state.userVerification);
  const { selectedUserDetails } = useSelector(
    (state) => state.userVerification
  );
  const [imageUploadPreview, setImageUploadPreview] = useState("");
  const User_Details = localStorage.getItem("USER_DETAILS");
  const { finalSubmitLoading } = useSelector((state) => state.userVerification);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const validation = Yup.object({
    otp: Yup.number()
      .typeError("Please enter a valid OTP")
      .positive("OTP must be a positive number")
      .integer("OTP must be an integer")
      .min(100000, "OTP must be at least 6 digits")
      .max(999999, "OTP must be at most 6 digits")
      .required("OTP is required"),
    // reward: Yup.number(),
    cash: Yup.number(),
    rewards:
      offerDetailsData?.vendor_type !== "CASH" &&
      Yup.array().of(
        Yup.object().shape({
          reward: Yup.object().required("Reward is required required"),
          reward_quantity: Yup.number()
            .required("Required*")
            .max(20, "Maximum 20"),
        })
      ),
    price: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
      rewards: [
        {
          reward: "",
          reward_quantity: "",
          reward_sum: "",
        },
      ],
      redeemed_points: offerDetailsData?.total_points ?? "",
      price: "",
      cash: 0,
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      const obj = {
        user_id: selectedUserDetails[0]?.id ?? User_Details?.id,
        vendor_otp: values?.otp,
        offer_id: offerDetailsData?.selected_offer,
        entered_point:
          priceSum < values?.redeemed_points
            ? priceSum
            : values?.redeemed_points,
        cash:
          offerDetailsData?.vendor_type === "CASH"
            ? offerDetailsData?.rewards?.[0]?.remaining_cash
            : values?.cash < 0
            ? 0
            : values?.cash,
      };

      let formData = new FormData();

      if (values?.image?.name) {
        obj.image = values?.image;
      }

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (offerDetailsData?.vendor_type !== "CASH") {
        values?.rewards?.forEach((item, index) => {
          formData.append(`reward_id[${index}].id`, item?.reward?.reward_id);
          formData.append(
            `reward_id[${index}].quantity`,
            item?.reward_quantity
          );
        });
      } else {
        offerDetailsData?.rewards?.forEach((item, index) => {
          formData.append(
            `reward_id[${index}].id`,
            offerDetailsData?.rewards[0]?.reward_id
          );
          formData.append(`reward_id[${index}].quantity`, 1);
        });
      }

      dispatch(submitUserVerification({ data: formData })).then((res) => {
        if (res?.payload?.statusCode === 200) {
          dispatch(pendingRequests.util.invalidateTags(["PendingRequests"]));
          toast.success(res?.payload?.message);
          navigate("/user-verification");
          localStorage.removeItem("USER_DETAILS");
          localStorage.removeItem("dashboard_permissions");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });

  //setting index while changing values
  useEffect(() => {
    if (currentIndex !== null) {
      handleSetRowSum(currentIndex);
      setCurrentIndex(null);
    }
    //eslint-disable-next-line
  }, [formik.values.rewards, currentIndex]);

  //sum of the total items which added
  const priceSum = formik?.values?.rewards?.reduce((total, item) => {
    return total + (item?.reward_sum || 0);
  }, 0);

  const handleChangeReward = (value, index) => {
    formik.setFieldValue(`rewards.${index}.reward`, value);
    formik.setFieldValue(`rewards.${index}.reward_quantity`, "");
    setCurrentIndex(index);
  };

  const handleChangeQuantity = (e, index) => {
    formik.setFieldValue(`rewards.${index}.reward_quantity`, e.target.value);
    setCurrentIndex(index);
  };

  const handleSetRowSum = (index) => {
    const reward = formik.values.rewards?.[index]?.reward;
    const quantity = formik.values.rewards?.[index]?.reward_quantity;

    if (!reward) {
      formik.setFieldError(
        `rewards.${index}.reward`,
        "Please choose the reward to determine the quantity"
      );
    } else if (!quantity) {
      formik.setFieldError(
        `rewards.${index}.reward_quantity`,
        "Please enter the reward quantity"
      );
    } else {
      formik.setFieldValue(
        `rewards.${index}.reward_sum`,
        +reward.price * +quantity
      );
    }
    // if (priceSum > (formik?.values?.redeemed_points || 0)) {
    //   setPriceError("the price exceeded the redeemed points");
    // } else {
    //   setPriceError("");
    // }

    if (priceSum) {
      const cashValue = priceSum - formik?.values?.redeemed_points;
      formik?.setFieldValue("cash", cashValue > 0 ? cashValue : 0);
    }
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUploadPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleAddReward = () => {
    let { rewards } = formik?.values;

    rewards = [
      ...rewards,
      {
        reward: "",
        reward_quantity: "",
        reward_sum: "",
      },
    ];
    formik.setFieldValue("rewards", rewards);
  };

  return {
    formik,
    offerDetailsData,
    imageUploadRef,
    imageUploadPreview,
    finalSubmitLoading,
    priceSum,
    handleChangeQuantity,
    handleAddReward,
    handleChangeReward,
    handleImageUpload,
  };
};

export default useOfferOtpVerificationModal;
